import React from "react";

const Privacy = () => {

  return (
    <div class="site-content">
      <section className="section-img">
        <div className="container">
          <div className="row">
            <div className="col-md-7 extra-large">
              <div className=" headabout">
                <h1 className="about-heading">Privacy Policy</h1>
              </div>
              <p className="our_vission01">As a reporting entity under the AML/CTF Act of Australia, RemitAssure is obligated to collect money transfer details which include the personal information of customers.  We would like to assure our customers that we take their privacy very seriously. Our handling of the personal information of customers is in line with the stipulations of Australia’s Privacy Act of 1988 (Privacy Act).</p>

              <p className="our_vission01">This Policy outlines our approach to handling your information. It also highlights your choices under the act. As used in this Policy, “we”, “our” and “us” refer to RemitAssure.</p>

              <h3 className="sub-heading">
                1. What We Collect
              </h3>
              <h5>1.1 Personal Information</h5>
              <p className="our_vission01">
                To use our Service, AML/CTF regulations require that we collect personal data that identifies you as an individual. Personal data we collect include:
                <ul className="list-sty">
                  <li>a. First and last name</li>
                  <li>b. Address</li>
                  <li>c. Phone number</li>
                  <li>d. Date of birth</li>
                  <li>e. Information required to confirm your identity</li>
                </ul>
                <p className="our_vission01">
                  We may also collect additional information such as government identification, country of birth, etc. Customers are advised not to share sensitive personal information (e.g. racial background, religion, criminal background, etc.)
                </p>
              </p>
              <h5>1.2 Transaction Information</h5>
              <p className="our_vission01">
                To use our Service, we may collect information about other parties to a transaction, the source of funds, reason for the transaction, payment details and device information. If you nominate a 3rd party recipient, we will collect their mailing address, telephone number and bank account details.
              </p>
              <p className="our_vission01">
                We require this information to deliver our services and to meet our regulatory obligations under the AML/CTF Act of Australia. By using our Service, you confirm your agreement to our use of this information, including that of the 3rd party to the transaction.
              </p>
              <h5>1.3 Cookies</h5>
              <p className="our_vission01">
                We collect information about how you interact with our website and mobile Apps. In some cases, we utilise various cookies and tracking mechanism to understand your preferences and to gauge the effectiveness of our marketing campaigns.
              </p>

              <h3 className="sub-heading">
                2. How We Collect Personal Information
              </h3>
              <p className="our_vission01">
                We collect information in a different way, including
              </p>
              <h5>2.1 Information Provided by You</h5>
              <p className="our_vission01">
                We collect your personal and other information provided during the know-your-customer (KYC) part of our onboarding process. We also collect information through your account preferences, sales promotions, and your interactions with us (e.g. phone calls, emails, etc.)
              </p>
              <h5>2.2 Information From Your Use of Our Services </h5>
              <p className="our_vission01">
                When you use our Services, we collect information provided by you for transactions. If you follow us on social media, you will be sharing your information with us, including the details of your social media contacts.
              </p>
              <p className="our_vission01">
                We also collect device finger-print details from the devices that you use to access our services e.g. mobile phone, IP addresses, etc.
              </p>
              <h5>2.3 Third Party Information</h5>
              <p className="our_vission01">
                For various reasons, including but not limited to verifying your identity, we collect information about you from third party sources, including government agencies, service providers, advertising agencies, etc.
              </p>
              <h3 className="sub-heading">
                3. How We Use your Information
              </h3>
              <p className="our_vission01">
                We use the information we collect from you and about you in several ways, including for:
              </p>
              <h5>3.1 Regulatory Obligations/Security</h5>
              <p className="our_vission01">
                <ul className="list-sty">
                  <li>a. Verify your identity during onboarding</li>
                  <li>b. Monitor and manage risks associated with offering money transfer services</li>
                  <li>c. Verify your ongoing access to your account on our platform</li>
                </ul>
              </p>
              <h5>3.2 Service Delivery and Improvement</h5>
              <p className="our_vission01">
                <ul className="list-sty">
                  <li>a. Provide money transfer services to you</li>
                  <li>b. Provide customer service support</li>
                  <li>c. Assess your suitability to use our service</li>
                  <li>d. To train our staff and to improve our services</li>

                </ul>
              </p>
              <h5>3.3 Communicate with You</h5>
              <p className="our_vission01">
                <ul className="list-sty">
                  <li>a. Provide information about your transactions (e.g. confirmations, receipts, etc.) </li>
                  <li>b. Respond to queries and support inquiries</li>
                  <li>c. Notify you of systems updates and enhancements</li>
                </ul>
              </p>
              <h5>3.4 Market and Advertise Our Services</h5>
              <p className="our_vission01">
                Develop marketing and promotional campaigns<br />
                Provide tailored offers to customers through diverse channels e.g. email, SMS, etc.
              </p>
              <h3 className="sub-heading">
                4. Information Sharing
              </h3>
              <p className="our_vission01">RemitAssure will never sell your personal information to marketers or third parties. We may disclose or share sanitized and aggregated data about you with third parties to enable us effectively to deliver our services.
              </p>
              <p className="our_vission01">
                We may share information about you with third parties if:
                <ul>
                  <li>we are obligated by law</li>
                  <li>you authorise to</li>
                  <li>it’s required for us to provide our services to you</li>
                </ul>
              </p>
              <p className="our_vission01">
                Typical third parties that we might share your information with include:
                <ul>
                  <li>Government agencies, e.g. AUSTRAC as part of our compliance and reporting requirements</li>
                  <li>Payment Processors and service providers to enable us process payment and payout for your transaction</li>
                  <li>ID verification agencies to help us meet our know-your-customer obligations and to confirm that you meet the criteria to use our services.</li>
                  <li>If authorised by you, we may share your data with our service providers and business partners whose services enable RemitAssure to deliver its services. </li>
                </ul>
              </p>
              <h3 className="sub-heading">
                5. How Long We Keep Your Information
              </h3>
              <p className="our_vission01">We require your personal information to deliver services to you. Under the subsisting AML/CTF Act of Australia, we are required to keep your personal information for up to 7 years from the cessation of relationship with you. If we end our business relationship, we will delete your personal information subject to this provision.
              </p>
              <h3 className="sub-heading">
                6. Rights and Choices
              </h3>
              <h5>6.1 Correct and Up to Date Information</h5>
              <p className="our_vission01">
                If your information changes, you should inform us as soon as possible to update your information on file. You may update your information directly by logging into your account or by contacting us through the ‘Contact Us’ information provided below.
              </p>
              <h5>6.1 Device Permissions</h5>
              <p className="our_vission01">
                You can disable location services and restrict the access of applications to your camera, notifications, and other sensitive materials and functions on your device. Please refer to the manufacturer’s manual of your device for instructions.
                <h6>Marketing Opt-Out</h6>
                <p className="our_vission01">You can opt-out anytime from our marketing communications by unsubscribing through your account preferences or directly from marketing emails.</p>
              </p>
              <h3 className="sub-heading">
                7. Complaints
              </h3>
              <p className="our_vission01">
                We take your Privacy very seriously and will prioritise investigation of any complaint. If you have any concerns about your information or privacy, please contact us immediately through the channels provided in the ‘Contact Us’ section below.
              </p>
              <h3 className="sub-heading">
                8. Contact Us
              </h3>
              <p className="our_vission01">
                <small>RemitAssure</small><br />
                Level 3, South Tower, 339 Coronation Drive<br />
                Milton, QLD 4064<br />
                Tel: 1300 284 228<br />
                Email: <a href="mailto:privacy@remitassure.com">privacy@remitassure.com</a> </p>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Privacy; 