import React from "react";

const GehPolicy = () => {

    return (
        <div class="site-content">
            <section className="section-img">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 extra-large">
                            <div className=" headabout">
                                <h1 className="about-heading">Gifts, Entertainment, and Hospitality (GEH) Policy</h1>
                            </div>
                            <h3 className="sub-heading">
                                1. Purpose
                            </h3>
                            <p className="our_vission01">The purpose of this policy is to establish guidelines for the appropriate offering and acceptance of gifts, entertainment, and hospitality (GEH) by employees of RemitAssure. This ensures compliance with applicable laws and regulations and maintains the integrity and reputation of our company.
                            </p>
                            <h3 className="sub-heading">
                                2. Scope
                            </h3>
                            <p className="our_vission01">This policy applies to all employees, officers, directors, contractors, and agents of RemitAssure (collectively referred to as "employees").
                            </p>
                            <h3 className="sub-heading">
                                3. Definitions
                            </h3>
                            <h5>3.1. Gift</h5>
                            <p className="our_vission01">
                                Any item of value given or received without the expectation of payment or something in return.
                            </p>
                            <h5>3.2. Entertainment</h5>
                            <p className="our_vission01">
                                Events, activities, or experiences provided for enjoyment, often including meals, tickets to events, or recreational activities.
                            </p>
                            <h5>3.3. Hospitality</h5>
                            <p className="our_vission01">
                                The friendly reception and treatment of guests, which may include lodging, meals, and other courtesies.
                            </p>

                            <h3 className="sub-heading">
                            4. General Principles
                            </h3>
                            <h5>4.1. Transparency</h5>
                            <p className="our_vission01">
                            All gifts, entertainment, and hospitality must be recorded and disclosed in accordance with this policy.
                            </p>
                            <h5>4.2. Integrity </h5>
                            <p className="our_vission01">
                            Actions must not influence, or appear to influence, business decisions or lead to conflicts of interest.
                            </p>
                            <h5>4.3. Legality</h5>
                            <p className="our_vission01">
                            All activities must comply with relevant laws and regulations, including anti-bribery and anti-corruption laws.
                            </p>
                            <h3 className="sub-heading">
                            5. Acceptable Practices
                            </h3>
                            <h5>5.1 Gifts</h5>
                            <p className="our_vission01">
                            Modest gifts (e.g., promotional items, holiday gifts) under a value of $50 may be accepted without prior approval.
                            </p>
                            <p className="our_vission01">
                            Gifts over $50 must be approved by a supervisor or the compliance department.
                            </p>
                            <p className="our_vission01">
                            Cash or cash equivalents (e.g., gift cards) are strictly prohibited.
                            </p>
                            <h5>5.2. Entertainment</h5>
                            <p className="our_vission01">
                            Occasional business-related meals and entertainment under a value of $100 per person are generally acceptable.
                            </p>
                            <p className="our_vission01">
                            Entertainment over $100 per person must be approved by a supervisor or the compliance department.
                            </p>
                            <p className="our_vission01">
                            Extravagant or frequent entertainment that could appear to influence decision-making is prohibited.
                            </p>
                            <h5>5.3. Hospitality</h5>
                            <p className="our_vission01">
                            Modest hospitality, such as accommodation and meals provided during business travel, is acceptable.
                            </p>
                            <p className="our_vission01">
                            Lavish or non-business-related hospitality must be approved by a supervisor or the compliance department.
                            </p>
                            <h3 className="sub-heading">
                            6. Prohibited Practices
                            </h3>
                            <p className="our_vission01">6.1. Soliciting gifts, entertainment, or hospitality from any party with whom RemitAssure conducts business.
                            </p>
                            <p className="our_vission01">
                            6.2. Accepting gifts, entertainment, or hospitality that could create a conflict of interest.
                            </p>
                            <p className="our_vission01">
                            6.3. Offering or accepting gifts, entertainment, or hospitality during a bidding or negotiation process.
                            </p>

                            <h3 className="sub-heading">
                            7. Reporting and Approval Process
                            </h3>
                            <p className="our_vission01">7.1. All gifts, entertainment, and hospitality over the specified thresholds must be reported using the GEH reporting form within 30 days of receipt or offer.
                            </p>
                            <p className="our_vission01">7.2.  Supervisors and the compliance department will review and approve or deny the reported items based on this policy.
                            </p>
                            <p className="our_vission01">7.3.  Records of all reported items must be maintained for auditing purposes.
                            </p>
                            <h3 className="sub-heading">
                            8. Compliance and Training
                            </h3>
                            <p className="our_vission01">
                            Employees must complete annual training on the GEH policy and related ethical standards. The compliance department will conduct regular audits to ensure adherence to this policy.
                            </p>
                            <h3 className="sub-heading">
                            9. Violations
                            </h3>
                            <p className="our_vission01">
                            Any violations of this policy may result in disciplinary action, up to and including termination of employment. Suspected violations must be reported to the compliance department or through the anonymous reporting hotline.
                            </p>
                            <h3 className="sub-heading">
                            10. Policy Review
                            </h3>
                            <p className="our_vission01">
                            This policy will be reviewed annually and updated as necessary to ensure compliance with applicable laws and regulations.
                            </p>
                            <h3 className="sub-heading">
                            11. Contact Information
                            </h3>
                            <p className="our_vission01">
                            For questions or further guidance on this GEH policy, please contact us through the following channels.
                            </p>
                            <p className="our_vission01">
                                <small>RemitAssure</small><br />
                                Level 3, South Tower, 339 Coronation Drive<br />
                                Milton, QLD 4064<br />
                                Tel: 1300 284 228<br />
                                Email: <a href="mailto:compliance@remitassure.com">compliance@remitassure.com</a> 
                                </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default GehPolicy; 