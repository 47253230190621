import React from "react";

const T_c = () => {
    return (
        <div class="site-content">
            <section className="section-img">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 term-co extra-large">

                            <div className=" headabout">
                                <h1 className="about-heading">Terms and conditions</h1>
                            </div>


                            <div>
                                <div className="my-4">
                                    <h6>About Us</h6>
                                    <p className="our_vission01">RemitAssure (“we” or “us”) provides online international money transfer services (“Services”). These Terms and Conditions, along with the terms on website and mobile apps constitute the entire agreement ("Agreement") between us and you, the individual User of the Service ("you" or "your") and govern your use of our Service.
                                    </p>
                                </div>
                                <div className="my-4">
                                    <h6>OUR SERVICE</h6>
                                    <ol style={{ listStyleType: "1" }}>
                                        <li>
                                            <p className="our_vission01">Our Service allows you to transfer ("Transfer”) money online to a person designated by you (the "Recipient") to receive into a bank account or other type of accounts which we may allow ("Payout Method"). We may apply limits to the amount you are able to send using the Service</p>
                                        </li>
                                        <li>
                                            <p className="our_vission01"> Use of the Service is subject to payment of the fee stated on the online form ("Fee"). We will not charge you any other fee for the Transfer. Please note that in addition to the Fee, a currency exchange rate may also be applied.
                                            </p>
                                        </li>
                                        <li><p className="our_vission01">We do not offer our services in all countries. You can call us or visit our website to confirm the availability of our services in specific countries.</p></li>
                                        <li><p className="our_vission01">The Service is for persons 18 years and over and residing in any of the countries of our operations. It may not be used for escrow or trust or gambling purposes and may only be used for a lawful purpose. PLEASE ALSO READ THE FRAUD WARNINGS ON THE ONLINE FORM. WE WILL NOT BE LIABLE IF YOU ASK US TO PAY A RECIPIENT AND YOU THEN CHANGE YOUR MIND AFTER THE RECIPIENT HAS BEEN PAID.</p></li>
                                        <li><p className="our_vission01">You must create a "Profile" before you can use our Service. In order to do so, you must provide some personal information, a "User ID" (which is your email address and is used for identification purposes and to log into your Profile) and a password which you must not share with anyone else. All information provided by you shall be stored in your Profile and shall be maintained by us or our "Service Providers" (any company or companies engaged by us or our contractors to provide the Service) based upon the information supplied by you.</p></li>
                                        <li><p className="our_vission01">A Transfer made in conjunction with the initial application for a Profile will be delayed until your identity and your payment details have been fully confirmed by RemitAssure.</p></li>
                                        <li><p className="our_vission01">You undertake to update your Profile if your email address or other contact information in your Profile change over time.</p></li>
                                        <li><p className="our_vission01">Records of your Transfers will be posted in your Profile section of our website. You may access the record at any time by logging in to your Profile.</p></li>
                                        <li><p className="our_vission01">To close your Profile, contact Customer Service through the RemitAssure website. If we close your account for any reason, we may, in our sole discretion, cancel your pending Transfer requests. </p></li>
                                        <li><p className="our_vission01">We may communicate with you electronically (e.g. by email) unless we are required by law or regulatory requirements to communicate in another way. If you do not wish to receive communications electronically, you may not use our Service.</p></li>
                                        <li><p className="our_vission01">We may, without notice (except as required by law) and without liability to you, terminate or suspend your access to your Profile at any time, including without limitation, if</p>
                                            <ol style={{ listStyleType: "lower-alpha" }}>
                                                <li>
                                                    <p className="our_vission01">you attempt to Transfer or charge funds from a credit or debit card that does not belong to you or which has expired, or if you are paying by credit card, there is an insufficient line of credit;</p>
                                                </li>
                                                <li><p className="our_vission01">your financial institution attempts to charge back a Transfer on the basis of a dispute related to a Transfer;</p></li>
                                                <li><p className="our_vission01">you provide incorrect or false information about yourself, your debit or credit card details or about a Recipient;</p></li>
                                                <li><p className="our_vission01">you attempt to tamper, hack, modify or otherwise corrupt the security or functionality of the Service;</p></li>
                                                <li><p className="our_vission01">we receive conflicting claims regarding ownership of, or the right to withdraw funds from a bank, debit or credit card account;</p></li>
                                                <li><p className="our_vission01">you have breached a term or condition of this Agreement, or any representation or warranty that you make under this Agreement is false;</p></li>
                                                <li><p className="our_vission01">we determine that your Profile is inactive;</p></li>
                                                <li><p className="our_vission01">we believe that the Transfer may expose us to action from any government or regulator.</p></li>
                                            </ol>
                                        </li>
                                        <li><p className="our_vission01">We may without liability to you, terminate or suspend your access to our Service at any time and for any reason by giving you 30 days' notice.</p></li>

                                        <li><p className="our_vission01">By logging into your Profile, completing your transaction details, ticking the Terms and Conditions box and clicking the ‘SUBMIT’ button and paying the Fee and Transfer amount as stated in the online form, you acknowledge that you</p>
                                            <ol style={{ listStyleType: "lower-alpha" }}>
                                                <li><p className="our_vission01">accept the terms of this Agreement,</p></li>
                                                <li><p className="our_vission01">consent to the execution of the Transfer,</p></li>
                                                <li><p className="our_vission01">agree to be bound by the terms and conditions relating to the Transfer</p></li>
                                                <li><p className="our_vission01">confirm that the information within the online form which you have provided is
                                                    true and accurate in all respects and;</p></li>
                                                <li><p className="our_vission01">authorise us (or parties authorised by us) to take payment from your nominated payment method, the Transfer amount and Fee. If we cannot collect payment for the Transfer from your debit or credit card, or the payment is reversed, we will have no further liability to you under this Agreement.</p></li>
                                            </ol>
                                        </li>
                                        <li><p className="our_vission01">After we receive your request to make a Transfer, we will confirm the Transfer and provide a reference number to you. You must call us if any of the information you provide changes before the Recipient collects or receives the money.</p></li>
                                        <li>
                                            <p className="our_vission01"> We will report Transfers to government authorities if we are required to do so by law and we may refuse to provide the Service (in whole or part) if we reasonably believe that:</p>
                                            <ol style={{ listStyleType: "lower-alpha" }}>
                                                <li><p className="our_vission01">by doing so we might breach any RemitAssure policy (including policies intended to prevent fraud, money laundering or terrorist financing) and/or break any applicable law, order of the court, regulation, code or other duty that applies to us or requirement of any regulatory or governmental authority, body or agency having jurisdiction over us;
                                                </p></li>
                                                <li><p className="our_vission01">doing so may expose us to action from any government or regulator;</p></li>
                                                <li><p className="our_vission01">it may be linked with fraudulent or illegal activity; or</p></li>
                                                <li><p className="our_vission01">we otherwise consider such action necessary to protect our interests. In each case
                                                    we are only able to provide the reasons for a refusal if allowed by law.</p></li>
                                            </ol>
                                        </li>
                                        <li><p className="our_vission01">You cannot initiate a Transfer on behalf of a third party</p></li>
                                        <li><p className="our_vission01">For online to account Transfers, we will send the money to the account you specified on the online form. Banking practices may vary depending on the Recipient country. For more information on when a payment will be credited to such an account, you need to contact the Recipient's account provider. The Recipient's account provider may apply its own charges to the Transfer, which do not involve us.</p></li>
                                    </ol>
                                </div>
                                <div className="my-4">
                                    <h6>Warranties</h6>
                                    <ol style={{ listStyleType: "number" }}>
                                        <li><p className="our_vission01">Where we have materially breached these Terms and Conditions causing a Sender loss, we will refund the Sender the Transaction Amount and the Service Fee.</p></li>
                                        <li><p className="our_vission01">If a Transaction is delayed or fails, or if an executed Transaction is not authorised, you may have a right to receive a refund or compensation under laws relating to the provision of international money transfer services. In the case of any unauthorised or incorrectly executed Transaction, any such right may be prejudiced if you do not notify us of the unauthorised or incorrectly executed Transaction without delay, or in any event within thirteen months after the debit date. We will provide you with further details of your rights to a refund or compensation if you contact us using the contact details provided in our Support Centre on our website <a href="https://remitassure.com">(https://remitassure.com)</a> or through our mobile apps.</p></li>
                                        <li><p className="our_vission01">Any claim for compensation made by you must be supported by relevant documentation.</p></li>
                                        <li><p className="our_vission01">If any loss that you suffer is not covered by a right to payment under the laws referred to in clause 1.3.2, we will only accept liability for that loss up to a limit which is the greater of</p>
                                            <ol style={{ listStyleType: "lower-alpha" }}>
                                                <li><p className="our_vission01">the amount of any Service Fee and</p></li>
                                                <li><p className="our_vission01">A$500, unless otherwise agreed by us in writing.</p></li>
                                            </ol>
                                            <p className="our_vission01">The cap on our liability only limits a claim for loss arising out of any single Transaction or series of related Transactions, or (if a loss does not arise out of a Transaction or related Transactions) any single act, omission or event or related acts, omissions or events. This means that if, for example, you suffer loss by reason of our failure to perform our agreement with you under two unrelated Transactions, you might be able to claim up to A$1,000.</p>
                                        </li>
                                        <li>
                                            <p className="our_vission01">  We do not, in any event, accept responsibility for:</p>
                                            <ol>
                                                <li><p className="our_vission01">any failure to perform the Service (e.g. your Instruction) as a result of circumstances which could reasonably be considered to be due to abnormal and unforeseen circumstances or outside our control or due to our obligations under any applicable laws, rules or regulations;</p></li>
                                            </ol>
                                        </li>
                                        <li><p className="our_vission01">malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness of messages you send to us;</p></li>
                                        <li><p className="our_vission01">any losses or delays in transmission of messages arising out of the use of any internet or telecommunications service provider or caused by any browser or other software which is not under our control; or</p></li>
                                        <li><p className="our_vission01">errors on the website or with the Service caused by incomplete or incorrect information provided to us by you or a third party.</p></li>
                                        <li><p className="our_vission01">Nothing in this clause 1.3 shall
                                            <li> exclude or limit liability on our part for death or personal injury resulting from our negligence; or</li>
                                            <li> exclude our liability for fraud.</li></p></li>
                                        <li><p className="our_vission01">Where you are sending a Transaction Amount to a Payee who is not registered with us, you agree to accept the provisions of this clause 1.3 not only for yourself, but also on behalf of the Payee.</p></li>
                                        <li><p className="our_vission01">Your relationship is with RemitAssure only. You agree that no affiliate or agent of RemitAssure owes you any duty of care when performing a task which would otherwise have to be performed by RemitAssure under its agreement with you.</p></li>
                                        <li><p className="our_vission01">You understand and acknowledge that you are liable for all losses incurred in respect of an unauthorised Transaction or Instruction or any other unauthorised use of the Service, where you have acted fraudulently or negligently and you agree to be responsible for and hold harmless RemitAssure, our subsidiaries, affiliates, officers, directors, employees, agents, independent contractors, advertisers, partners, and co- branders from all loss, damage, claims, actions or demands, including reasonable legal fees, arising out of your use or misuse of this website or Service, all activities that occur under your password, Registration or e-mail login, your violation of these Terms and Conditions or any other violation of the rights of another person or party.
                                        </p></li>
                                    </ol>
                                </div>
                                <div className="my-4">
                                    <h6>Refunds</h6>
                                    <ol>
                                        <li><p className="our_vission01">Within the dictates of the law, RemitAssure is not bound to revoke transactions once your instructions have been received. </p></li>
                                        <li><p className="our_vission01">However, we may at our discretion attempt to cancel or revoke a transaction. In some cases, our efforts might be limited by the status of the transaction. Once payout instructions have been issued for a transaction, we cannot guarantee successful cancellation. For successful revocations, RemitAssure will normally refund your money, less any reasonable revocation charges and any Service Fees already charged, within four (5) Business Days.</p></li>
                                        <li><p className="our_vission01">If you have any problems using the Service or are aware of any unauthorised or incorrectly executed Transaction, you should contact us through channels listed in Support Centre on our website <a href="https://remitassure.com">(https://remitassure.com)</a> or through mobile apps without delay and in any event no later than 13 months after the date the Transaction Amount was debited, upon becoming aware of the unauthorised or incorrectly executed Transaction. A request for a refund must be submitted in writing by email to our Support Centre providing the Sender's full name, address, and phone number, together with the Transaction ID, Transaction Amount, and the reason for your refund request.
                                        </p></li>
                                        <li><p className="our_vission01">If we have executed the Transaction in accordance with the instructions you have provided to us, and that information proves to have been incorrect, we are not liable for the incorrect execution of the Transaction. We will however make reasonable efforts to recover the funds. We may charge you a reasonable fee, reflective of our efforts, to do so.
                                        </p></li>
                                        <li><p className="our_vission01">Where RemitAssure has executed the Transaction otherwise than in accordance with your Instruction, RemitAssure will refund the full amount debited. Unless there are exceptional circumstances, no adjustment will be made for any currency fluctuations which may have occurred between the time you pay us the Transaction Amount and the time of credit.</p></li>
                                        <li><p className="our_vission01">Any refunds will be credited back to the same Payment Instrument used to fund the Transaction and in the same currency.</p></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default T_c; 