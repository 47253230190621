export const reciepientAreaList = [
    { name: "AU", code: "61" },
    { name: "GH", code: "233" },
    { name: "KE", code: "254" },
    { name: "NZ", code: "64" },
    { name: "NG", code: "234" },
    { name: "PH", code: "63" },
    { name: "TH", code: "66" },
    { name: "VN", code: "84" },
]

export const senderAreaList = [
    { name: "AU", code: "61" },
    { name: "NZ", code: "64" },
]